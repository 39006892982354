import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';  
import { DemoMeetingApp } from './chime.abstract';

@Component({
  selector: 'app-chime',
  templateUrl: './chime.component.html',
  styleUrls: ['./chime.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChimeComponent implements OnInit   {
  // @Input() queries: any;
  appointmentID: string = "";
  constructor() {}

  ngOnInit() { 
    // const params = new URL(window.location.href).searchParams;
    // if(!params.get('m')){
    //   window.history.pushState(null, '', window.location.href+this.queries); 
    // }
    // else{ 
    //   if(this.queries != decodeURI(new URL(window.location.href).search)){  
    //     let newURL = new URL(window.location.href);
    //     newURL.search = "";
    //     location.href = newURL.href+this.queries; 
    //   }
    // }

    setTimeout(() => {  
      this.initJS();
      setTimeout(() => {
        new DemoMeetingApp();  
      }, 1000);
      // this.initializeQueryParams();
    }, 1000);
  }

  initializeQueryParams() { 
      this.appointmentID = new URL(window.location.href).searchParams.get('appointmentID'); 
  }

  updateQueryStringParameter(uri, key, value) {
    var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    var separator = uri.indexOf('?') !== -1 ? "&" : "?";
    if (uri.match(re)) {
      return uri.replace(re, '$1' + key + "=" + value + '$2');
    }
    else {
      return uri + separator + key + "=" + value;
    }
  }

  onPermissionSaveTrans(choice){ 
    (<any>document.getElementById("permission-save-choice")).value = choice;
  }

  onCloseModal(nameModal){
    document.getElementById(nameModal).style.display = 'none';
  }

  onEndCall(status){
    this.initializeQueryParams();
    console.log("onEndCall",status);
    if(status === 'yes'){ 
      window.location.reload();
    } 
    else if(status === 'yesandsurvey') {
      // window.open(`${environment.surveyLink}?AppointmentID=${this.appointmentID}`);
      // window.open(`test?AppointmentID=${this.appointmentID}`);
      window.location.reload();
    }
    document.getElementById('endcall-confirmation').style.display = 'none'; 
  }

  initJS(){ 
    
    function uuidv4() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    }

    const urlParams = new URLSearchParams(window.location.search);
    const name = urlParams.get('n');
    console.log(document.getElementById("inputName"));
    console.log(name);

    (<any>document.getElementById("inputName")).value = name+"${=||"+uuidv4().slice(0,3); 
  
    setInterval(() => { 
      if(!document.getElementById("chime-body")){return;}
      let findContent = false;
      for(let i = 0; i < 28; i++){
        const attendid = document.getElementById("attendeeid-"+i.toString())?.innerHTML.toString();
        if(attendid){
          const attend_f = attendid.includes("content");
          if(!attend_f){
            const tileElement = document.getElementById(`tile-${i}`);
            tileElement.classList.remove('featured');
          }else{
            const tileElementisActive = document.getElementById(`tile-${i}`);
            const tileElementisActive_f= tileElementisActive.classList.contains("active");
            if(tileElementisActive_f){
            findContent = true;
            }
          }
        }
      }

      if(!findContent){
        const tileArea = document.getElementById('tile-area');
        tileArea.classList.remove('featured');
      } 
    }, 1000);

    const btnEndCall = document.getElementById('button-end-call');
    btnEndCall.addEventListener('click', () => { 
      console.log("End Call Clicked");
      document.getElementById('endcall-confirmation').style.display = 'block'; 
    });
 

    document.getElementById("more-action").addEventListener('click',()=>{
      document.getElementById("more-action").style.display ='none';
    });

    document.getElementById("button-more-action").addEventListener('click',()=>{ 
      document.getElementById("more-action").style.display ='grid';
    });
  
    function checkInitialScreen() {
      if(!document.getElementById("chime-body")){return;}
      let browserWidth = document.getElementById("chime-body").offsetWidth;
      // document.getElementById("chime-body").wid
      console.log("browserWidth",browserWidth);
      // if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) { 
      //   browserWidth = screen.width;
      // }
      // if (window.matchMedia("(min-width: 901px)").matches) { 
      // if (browserWidth > 1160 ) { 

      // document.getElementById("roster-tile-container").style.width = "100%";
      // document.getElementById("roster-tile-container").style.maxWidth = "100%";
      if(true){ // force UI
      // if(browserWidth >= 858){ // force UI
        for(let tile of (<any>document.getElementsByClassName("video-tile"))){ 
          tile.style.width ="500px";
          tile.style.height ="100%";
        } 
        
        for(let element of (<any>document.getElementsByClassName("c-btn-group-1"))){ 
          element.style.borderRadius = "0.2rem"; 
          element.style.borderTopRightRadius = "0px"; 
          element.style.borderBottomRightRadius = "0px";  
          element.style.width = "auto";
        } 
        for(let element of (<any>document.getElementsByClassName("c-btn-group-2-1"))){ 
          element.style.borderRadius = "0.2rem"; 
          element.style.borderTopRightRadius = "0px"; 
          element.style.borderBottomRightRadius = "0px";  
        }  
        for(let element of (<any>document.getElementsByClassName("c-btn-group-3-1"))){ 
          element.style.width = "auto";
        }  
        for(let element of (<any>document.getElementsByClassName("c-btn-group"))){ 
          element.hidden = false;
        }   

        document.getElementById('meeting-container').style.minWidth = 'inherit';
        document.getElementById("tile-area").style.gridTemplateColumns ="repeat(1, 1fr)";
        document.getElementById('meeting-container').style.gridTemplateAreas = `'one two' 'three three'`;
        document.getElementById('meeting-container').style.gridTemplateRows = '75px auto';
        document.getElementById('meeting-container').style.gridTemplateColumns = 'auto 300px';
        //document.getElementById('meeting-container').style.minWidth = '1000px';
      //   document.getElementById('meeting-container').style.overflow = 'auto';  
        document.getElementById('camera-prompt').style.transform = 'translateX(0px) translateY(50%)';
        document.getElementById('camera-prompt-arrow').style.transform = 'translateY(10px) translateX(-74px) rotate(45deg)';
        
      
        document.getElementById('buttons-major').style.maxWidth = '100%';
        document.getElementById('buttons-major').style.setProperty("justify-content", "inherit", "important"); 
        document.getElementById('buttons-major').style.setProperty("text-align", "inherit", "important");
        document.getElementById('buttons-minor').style.maxWidth = '100%';
        document.getElementById('buttons-minor').style.setProperty("justify-content", "flex-end", "important"); 
        document.getElementById('buttons-minor').style.setProperty("text-align", "inherit", "important");

        smallBtnGroup();
        //var labels = document.getElementsByClassName('button-label')
        //for(var i = 0; i < labels.length; i++){
        //  labels[i].style.display = 'inline';
        //}
      }
      // else if (browserWidth >= 990 && browserWidth <= 1160) {  
        
      else if (browserWidth >= 820 && browserWidth <= 857) {  
        for(let tile of (<any>document.getElementsByClassName("video-tile"))){ 
          tile.style.width ="500px";
          tile.style.height ="100%";
        } 
        for(let element of (<any>document.getElementsByClassName("c-btn-group-1"))){ 
          element.style.borderRadius = "0.2rem"; 
          element.style.borderTopRightRadius = "0px"; 
          element.style.borderBottomRightRadius = "0px";  
          element.style.width = "auto";
        } 
        for(let element of (<any>document.getElementsByClassName("c-btn-group-2-1"))){ 
          element.style.borderRadius = "0.2rem"; 
          element.style.borderTopRightRadius = "0px"; 
          element.style.borderBottomRightRadius = "0px";  
        }  
        for(let element of (<any>document.getElementsByClassName("c-btn-group-3-1"))){ 
          element.style.width = "auto";
        }  
        for(let element of (<any>document.getElementsByClassName("c-btn-group"))){ 
          element.hidden = false;
        }  
        
        document.getElementById("tile-area").style.gridTemplateColumns ="repeat(1, 1fr)";
        document.getElementById('meeting-container').style.gridTemplateAreas = ` 'one' 'two' 'three' `;
        document.getElementById('meeting-container').style.gridTemplateRows = '75px 75px auto ';
        document.getElementById('meeting-container').style.gridTemplateColumns = 'auto';
        document.getElementById('meeting-container').style.minWidth = '500px';
      //   document.getElementById('meeting-container').style.overflow = 'auto'; 
        document.getElementById('camera-prompt').style.transform = 'translateX(0px) translateY(50%)';
        document.getElementById('camera-prompt-arrow').style.transform = 'translateY(10px) translateX(-74px) rotate(45deg)';

        document.getElementById('buttons-major').style.maxWidth = '100%';
        document.getElementById('buttons-major').style.setProperty("justify-content", "flex-end", "important"); 
        document.getElementById('buttons-major').style.setProperty("text-align", "inherit", "important");
        document.getElementById('buttons-minor').style.maxWidth = '100%';
        document.getElementById('buttons-minor').style.setProperty("justify-content", "flex-end", "important"); 
        document.getElementById('buttons-minor').style.setProperty("text-align", "inherit", "important");
       
        smallBtnGroup();
        //var labels = document.getElementsByClassName('button-label')
        //for(var i = 0; i < labels.length; i++){
        //  labels[i].style.display = 'inline';
        //}
      }
      // else if (window.matchMedia("(min-width: 801px)").matches && window.matchMedia("(max-width: 900px)").matches) {
      else if (browserWidth >= 846 && browserWidth <= 990) {
        for(let tile of (<any>document.getElementsByClassName("video-tile"))){ 
          tile.style.setProperty("width", "inherit", "important");
          tile.style.setProperty("height", "inherit", "important");
        } 
        for(let element of (<any>document.getElementsByClassName("c-btn-group-1"))){ 
          element.style.borderRadius = "0.2rem"; 
          element.style.borderTopRightRadius = "0px"; 
          element.style.borderBottomRightRadius = "0px";  
          element.style.width = "auto";
        } 
        for(let element of (<any>document.getElementsByClassName("c-btn-group-2-1"))){ 
          element.style.borderRadius = "0.2rem"; 
          element.style.borderTopRightRadius = "0px"; 
          element.style.borderBottomRightRadius = "0px";  
        } 
        for(let element of (<any>document.getElementsByClassName("c-btn-group-3-1"))){ 
          element.style.width = "auto";
        }   
        for(let element of (<any>document.getElementsByClassName("c-btn-group"))){ 
          element.hidden = false;
        }  
        document.getElementById("tile-area").style.gridTemplateColumns ="repeat(1, 1fr)";
        document.getElementById('meeting-container').style.gridTemplateAreas = `'three three' 'one two'`;
        document.getElementById('meeting-container').style.gridTemplateRows = 'auto 100px';
        document.getElementById('meeting-container').style.gridTemplateColumns = 'auto auto';
        document.getElementById('meeting-container').style.minWidth = 'inherit';
        // document.getElementById('meeting-container').style.minWidth = '800px';
      //   document.getElementById('meeting-container').style.overflow = 'auto';
        document.getElementById('camera-prompt').style.transform = 'translateX(-40%) translateY(-120%)';
        document.getElementById('camera-prompt-arrow').style.transform = 'translateY(70px) rotate(45deg)'; 

        
        document.getElementById('buttons-major').style.maxWidth = '100%';
        document.getElementById('buttons-major').style.setProperty("justify-content", "space-around", "important"); 
        document.getElementById('buttons-major').style.setProperty("text-align", "right", "important");
        document.getElementById('buttons-minor').style.maxWidth = '100%';
        document.getElementById('buttons-minor').style.setProperty("justify-content", "space-around", "important"); 
        document.getElementById('buttons-minor').style.setProperty("text-align", "right", "important");
       
        smallBtnGroup();
        //var labels = document.getElementsByClassName('button-label')
        //for(var i = 0; i < labels.length; i++){
        //  labels[i].style.display = 'inline';
        //}
      }
      else if (browserWidth >= 538 && browserWidth <= 845) {
        // document.getElementById("roster-tile-container").style.width = "97%";
        // document.getElementById("roster-tile-container").style.maxWidth = "97%";
        for(let tile of (<any>document.getElementsByClassName("video-tile"))){ 
          tile.style.setProperty("width", "inherit", "important");
          tile.style.setProperty("height", "inherit", "important");
        } 
        for(let element of (<any>document.getElementsByClassName("c-btn-group-1"))){ 
          element.style.borderRadius = "0.2rem"; 
          element.style.borderTopRightRadius = "0px"; 
          element.style.borderBottomRightRadius = "0px";  
          element.style.width = "auto";
        } 
        for(let element of (<any>document.getElementsByClassName("c-btn-group-3-1"))){ 
          element.style.width = "auto";
        }  
        for(let element of (<any>document.getElementsByClassName("c-btn-group-2-1"))){ 
          element.style.borderRadius = "0.2rem"; 
          element.style.borderTopRightRadius = "0px"; 
          element.style.borderBottomRightRadius = "0px";  
        }  
        for(let element of (<any>document.getElementsByClassName("c-btn-group"))){ 
          element.hidden = false;
        }  
        document.getElementById("tile-area").style.gridTemplateColumns ="repeat(2, 1fr)";
        document.getElementById('meeting-container').style.gridTemplateAreas = `'three three' 'one two'`;
        document.getElementById('meeting-container').style.gridTemplateRows = 'auto 100px';
        document.getElementById('meeting-container').style.gridTemplateColumns = 'auto auto';
        document.getElementById('meeting-container').style.minWidth = 'inherit';
        // document.getElementById('meeting-container').style.minWidth = '800px';
      //   document.getElementById('meeting-container').style.overflow = 'auto';
        document.getElementById('camera-prompt').style.transform = 'translateX(-40%) translateY(-120%)';
        document.getElementById('camera-prompt-arrow').style.transform = 'translateY(70px) rotate(45deg)'; 

        
        document.getElementById('buttons-major').style.maxWidth = '100%';
        document.getElementById('buttons-major').style.setProperty("justify-content", "space-around", "important"); 
        document.getElementById('buttons-major').style.setProperty("text-align", "right", "important");
        document.getElementById('buttons-minor').style.maxWidth = '100%';
        document.getElementById('buttons-minor').style.setProperty("justify-content", "space-around", "important"); 
        document.getElementById('buttons-minor').style.setProperty("text-align", "right", "important");
       
        smallBtnGroup(); 
      }  
      else if (browserWidth >= 376 && browserWidth <= 537) {
        for(let tile of (<any>document.getElementsByClassName("video-tile"))){ 
          tile.style.setProperty("width", "inherit", "important");
          tile.style.setProperty("height", "inherit", "important");
        } 
        for(let element of (<any>document.getElementsByClassName("c-btn-group-1"))){ 
          element.style.borderRadius = "0.2rem"; 
          element.style.borderTopRightRadius = "0px"; 
          element.style.borderBottomRightRadius = "0px";  
          element.style.width = "auto";
        } 
        for(let element of (<any>document.getElementsByClassName("c-btn-group-3-1"))){ 
          element.style.width = "auto";
        }  
        for(let element of (<any>document.getElementsByClassName("c-btn-group-2-1"))){ 
          element.style.borderRadius = "0.2rem"; 
          element.style.borderTopRightRadius = "0px"; 
          element.style.borderBottomRightRadius = "0px";  
        }  
        for(let element of (<any>document.getElementsByClassName("c-btn-group"))){ 
          element.hidden = false;
        }  
        document.getElementById('meeting-container').style.minWidth = 'inherit';
        document.getElementById("tile-area").style.gridTemplateColumns ="repeat(1, 1fr)";
        document.getElementById('meeting-container').style.gridTemplateAreas = ` 'two' 'three' 'one'`;
        document.getElementById('meeting-container').style.gridTemplateRows = '75px auto 75px';
        document.getElementById('meeting-container').style.gridTemplateColumns = 'inherit'; 
        document.getElementById('meeting-container').style.setProperty("minWidth", "inherit", "important"); 
        document.getElementById('camera-prompt').style.transform = 'translateX(0px) translateY(50%)';
        document.getElementById('camera-prompt-arrow').style.transform = 'translateY(10px) translateX(-74px) rotate(45deg)';
        document.getElementById('buttons-major').style.maxWidth = '100%';
        document.getElementById('buttons-major').style.setProperty("justify-content", "space-around", "important"); 
        document.getElementById('buttons-major').style.setProperty("text-align", "right", "important");
        document.getElementById('buttons-minor').style.maxWidth = '100%';
        document.getElementById('buttons-minor').style.setProperty("justify-content", "space-around", "important"); 
        document.getElementById('buttons-minor').style.setProperty("text-align", "right", "important");
       
        expandBtnGroup(); 
      } else{
        for(let tile of (<any>document.getElementsByClassName("video-tile"))){ 
          tile.style.setProperty("width", "inherit", "important");
          tile.style.setProperty("height", "inherit", "important");
        } 
        for(let element of (<any>document.getElementsByClassName("c-btn-group-1"))){ 
          element.style.borderRadius = "0.2rem"; 
          element.style.width = "65px";
        } 
        for(let element of (<any>document.getElementsByClassName("c-btn-group-2-1"))){ 
          element.style.borderRadius = "0.2rem";  
        }  
        for(let element of (<any>document.getElementsByClassName("c-btn-group-3-1"))){ 
          element.style.width = "65px";
        }  
        for(let element of (<any>document.getElementsByClassName("c-btn-group"))){ 
          element.hidden = true;
        }  

        document.getElementById('meeting-container').style.minWidth = 'inherit';
        document.getElementById("tile-area").style.gridTemplateColumns ="repeat(1, 1fr)";
        document.getElementById('meeting-container').style.gridTemplateAreas = ` 'two' 'three' 'one'`;
        document.getElementById('meeting-container').style.gridTemplateRows = '75px auto 75px';  
        document.getElementById('meeting-container').style.gridTemplateColumns = 'inherit';
        document.getElementById('meeting-container').style.setProperty("minWidth", "inherit", "important"); 
        document.getElementById('camera-prompt').style.transform = 'translateX(0px) translateY(50%)';
        document.getElementById('camera-prompt-arrow').style.transform = 'translateY(10px) translateX(-74px) rotate(45deg)';
        document.getElementById('buttons-major').style.maxWidth = '100%';
        document.getElementById('buttons-major').style.setProperty("justify-content", "space-around", "important"); 
        document.getElementById('buttons-major').style.setProperty("text-align", "right", "important");
        document.getElementById('buttons-minor').style.maxWidth = '100%';
        document.getElementById('buttons-minor').style.setProperty("justify-content", "space-around", "important"); 
        document.getElementById('buttons-minor').style.setProperty("text-align", "right", "important");
       
        
        expandBtnGroup(); 
      }
    }
    setInterval(()=>{   
      checkInitialScreen()
    },1000);

    function smallBtnGroup(){
      var isBtnGroup = false;
      var btnLabels = (<any>document.getElementsByClassName("btn-with-label")); 
      for(let label of btnLabels){
          for(var child of label.children){  
              if(child.className){
                  if(child.className.includes("btn-group") || child.id == "button-end-call"){ 
                      var spans = child.getElementsByTagName('span');
                      if(spans.length>0){
                          spans[0].style.display = 'none';
                      }
                      isBtnGroup = true;
                  }
              } 
              if(child.localName == "span" && isBtnGroup){
                  child.style.display = "inherit";
                  child.style.fontSize ="10px";
                  isBtnGroup = false;
              }
          }   
      }
    }

    function expandBtnGroup(){
      var isBtnGroup = false;
      var btnLabels = (<any>document.getElementsByClassName("btn-with-label")); 
      for(let label of btnLabels){
          for(var child of label.children){  
              if(child.className){
                  if(child.className.includes("btn-group") || child.id == "button-end-call"){ 
                      var spans = child.getElementsByTagName('span');
                      if(spans.length>0){
                          spans[0].style.display = 'contents';
                          isBtnGroup = true;
                      }
                  }
              } 
              if(child.localName == "span" && isBtnGroup){
                  child.style.display = "none";
                  child.style.fontSize ="12px";
                  isBtnGroup = false;
              }
          }   
      }
    }
  }

}

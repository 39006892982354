import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [ 
  // { path: '', redirectTo: 'room', pathMatch: 'full' },
  {
    path:'',
    loadChildren:()=>import('./pages/ccn-chime/ccn-chime.module').then(m=>m.CcnChimeModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

<div class="container chime--main__container">
  <div class="row chime--sub__container">
    <div class="col">

      <div id="chime-body"
        style=" height: 100%;   height: 100%; display: -ms-flexbox; display: -webkit-box; display: flex; -ms-flex-align: center; -ms-flex-pack: center; -webkit-box-align: center; align-items: center; -webkit-box-pack: center; justify-content: center; position: relative;">

        <div id="flow-fatal" class="flow">
          <div style="vertical-align: middle; text-align: left; width: 40%; margin: 10em 30% 10em 30%;">
            <h1>Fatal error</h1>
            <p>An error was thrown that should not occur in ordinary use. This should be considered a fatal error for
              testing
              and canary purposes.</p>
            <p>This error will ordinarily be muffled, but you should consider removing this code if you are building an
              app
              based on this demo.</p>
            <code id="stack"></code>
          </div>
        </div>

        <template id="video-tile-template">
          <video class="video-tile-video"></video>
          <div class="video-tile-attendee-id"></div>
          <div class="video-tile-nameplate"></div>
          <div class="video-tile-pause-state"></div>
          <div class="button-video-tile-config" role="group">
            <button type="button" class="btn btn-success dropdown-toggle button-video-tile-config-drop"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="Video Tile Settings">
              <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8">
                <path
                  d="M3.5 0l-.5 1.19c-.1.03-.19.08-.28.13l-1.19-.5-.72.72.5 1.19c-.05.1-.09.18-.13.28l-1.19.5v1l1.19.5c.04.1.08.18.13.28l-.5 1.19.72.72 1.19-.5c.09.04.18.09.28.13l.5 1.19h1l.5-1.19c.09-.04.19-.08.28-.13l1.19.5.72-.72-.5-1.19c.04-.09.09-.19.13-.28l1.19-.5v-1l-1.19-.5c-.03-.09-.08-.19-.13-.28l.5-1.19-.72-.72-1.19.5c-.09-.04-.19-.09-.28-.13l-.5-1.19h-1zm.5 2.5c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5-1.5-.67-1.5-1.5.67-1.5 1.5-1.5z" />
              </svg>

            </button>
            <div class="dropdown-menu dropdown-menu-right dropdown-video-tile-config"
              aria-labelledby="button-video-tile-config-drop"
              style="position: absolute; transform: translate3d(0px, 38px, 0px); top: 0px; left: 0px; will-change: transform;">
              <a class="dropdown-item video-tile-pause">Pause</a>
              <div class="dropdown-divider"></div>
              <h6 class="dropdown-header target-resolution-header">Target Resolution</h6>
              <form class="btn-group btn-group-toggle video-tile-config-toggle target-resolution-toggle"
                data-toggle="buttons">
                <label class="btn btn-secondary">
                  <input type="radio" name="level" value="low" autocomplete="off">Low
                </label>
                <label class="btn btn-secondary">
                  <input type="radio" name="level" value="medium" autocomplete="off">Medium
                </label>
                <label class="btn btn-secondary active">
                  <input type="radio" name="level" value="high" autocomplete="off" checked>High
                </label>
              </form>
              <h6 class="dropdown-header video-priority-header">Video Priority</h6>
              <form class="btn-group btn-group-toggle video-tile-config-toggle video-priority-toggle"
                data-toggle="buttons">
                <label class="btn btn-secondary">
                  <input type="radio" name="level" value="low" autocomplete="off">Low
                </label>
                <label class="btn btn-secondary active">
                  <input type="radio" name="level" value="medium" autocomplete="off" checked>Medium
                </label>
                <label class="btn btn-secondary">
                  <input type="radio" name="level" value="high" autocomplete="off">High
                </label>
              </form>
            </div>
          </div>
        </template>

        <!-- Initial meeting authentication screen with meeting and name inputs -->
        <div id="flow-authenticate" class="flow text-center p-2" style="margin:160px 0px;">

          <div class="c-chime-logo-main col-12 col-sm-12 col-lg-10">
            <svg class="chime-logo" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 339.5 89.5"
              style="enable-background:new 0 0 339.5 89.5;" xml:space="preserve">
              <style type="text/css">
                .st0 {
                  fill: #1A3E70;
                }

                .st1 {
                  fill: #003B71;
                }

                .st2 {
                  fill: #93D500;
                }

              </style>
              <g>
                <g>
                  <g>
                    <path class="st0"
                      d="M318.3,8.9h-2.2V8h5.7v0.9h-2.2v6h-1.3V8.9z M323.1,8h1.1l2.5,4.2l2.5-4.2h1v7h-1.1v-4.9l-2.1,3.4h-0.7 l-2.1-3.4V15h-1.1V8z" />
                  </g>
                  <g>
                    <path class="st1"
                      d="M32.9,8c6.3,0,17.7,2.7,17.7,10.2v4.6h-6.1v-2.7c0-3.9-6.7-5.9-11.4-5.9c-9.5,0-16.8,7.1-16.8,17.7 c0,10.3,7.4,18.5,17,18.5c9.3,0,14.9-6.4,14.9-6.4l3.6,4.8c0,0-6.7,7.9-18.6,7.9c-14.1,0-23.9-10.9-23.9-24.7 C9.3,18.3,19.4,8,32.9,8z" />
                    <path class="st1"
                      d="M107.7,50.1h2.3c0.9,0,1.4-0.5,1.5-1.5l3.2-39.8h6.9l11.1,24.9c1.1,2.5,2.3,5.9,2.3,5.9h0.1 c0,0,1.2-3.4,2.3-5.9l11.1-24.9h6.9l3.2,39.8c0.1,0.9,0.5,1.5,1.5,1.5h2.2v5.7h-5.9c-3,0-4.1-1.3-4.3-4.3l-1.9-24.9 c-0.2-2.8-0.1-6.9-0.1-6.9h-0.1c0,0-1.3,4.4-2.5,6.9l-9.5,20.7h-5.6l-9.5-20.7c-1.1-2.5-2.5-7-2.5-7H120c0,0,0.1,4.2-0.1,7 L118,51.6c-0.2,3-1.3,4.3-4.3,4.3h-5.9V50.1z" />
                    <path class="st1"
                      d="M170.5,14.6h-4.3V8.8h20.8c8.6,0,14.8,5.8,14.8,14.7s-6.1,14.9-14.8,14.9h-9.7v17.4h-6.7V14.6z M185.8,32.7 c5.5,0,8.9-3.5,8.9-9.2s-3.4-9-8.9-9h-8.7v18.2H185.8z" />
                    <path class="st1"
                      d="M200.1,50.1h0.9c1.2,0,1.8-0.3,2.2-1.5l14.8-39.8h7l14.8,39.8c0.4,1.2,1.1,1.5,2.2,1.5h1v5.7h-3.7 c-3.3,0-4.4-0.8-5.5-3.9l-3.5-9.7h-17.4l-3.5,9.7c-1.1,3.1-2.2,3.9-5.5,3.9h-3.7V50.1z M228.5,36.7l-5.1-14.3 c-0.9-2.5-1.9-6.6-1.9-6.6h-0.1c0,0-0.9,4.1-1.8,6.6l-5.1,14.3H228.5z" />
                    <path class="st1"
                      d="M249.9,44.9c0,0,4.8,5.7,11.8,5.7c4.4,0,8.1-2.7,8.1-7c0-9.9-22.8-8.3-22.8-23c0-7.1,6.2-12.6,15.1-12.6 c5,0,13.4,2.3,13.4,8.7v3.9h-6.1v-2.2c0-2.5-3.5-4.3-7.3-4.3c-5.1,0-8.3,2.8-8.3,6.3c0,9.5,22.7,7.4,22.7,22.9 c0,7.2-5.6,13.4-15,13.4c-10,0-15.6-7-15.6-7L249.9,44.9z" />
                    <path class="st1"
                      d="M285.4,44.9c0,0,4.8,5.7,11.8,5.7c4.4,0,8.1-2.7,8.1-7c0-9.9-22.8-8.3-22.8-23c0-7.1,6.2-12.6,15.1-12.6 c5,0,13.4,2.3,13.4,8.7v3.9h-6.1v-2.2c0-2.5-3.5-4.3-7.3-4.3c-5.1,0-8.3,2.8-8.3,6.3c0,9.5,22.7,7.4,22.7,22.9 c0,7.2-5.6,13.4-15,13.4c-10,0-15.6-7-15.6-7L285.4,44.9z" />
                    <g>
                      <g>
                        <g>
                          <g>
                            <polygon class="st1" points="86.2,37.5 77.8,33.6 69.5,37.5 77.8,22.1 							" />
                          </g>
                        </g>
                        <path class="st2"
                          d="M105.8,31.7L93,27.5c-0.5-1.8-1.3-3.5-2.4-5l0.5-2l0.9-3.3l-3.3,0.9l-2.1,0.6c-1.3-0.9-2.9-1.6-4.5-2.1 L77.9,3.8l-4.3,12.8c-1.7,0.5-3.3,1.3-4.8,2.3l-2.4-0.6l-3.3-0.9l0.9,3.3l0.7,2.5c-0.9,1.3-1.6,2.8-2,4.4l-12.8,4.2L62.7,36 c0.4,1.5,1,2.9,1.8,4.1L63.7,43l-0.9,3.3l3.3-0.9l2.8-0.7c1.4,1,3,1.7,4.7,2.2l4.3,12.8l0,0l4.3-12.8c1.6-0.4,3.1-1.1,4.4-2 l2.4,0.7l3.3,0.9l-0.9-3.3l-0.6-2.3c1-1.4,1.8-3,2.3-4.8L105.8,31.7z M77.9,44.7c-7.2,0-13-5.8-13-13s5.8-13,13-13 c7.2,0,13,5.8,13,13S85,44.7,77.9,44.7z" />
                      </g>
                    </g>
                    <path class="st2"
                      d="M80.3,7.4l0.8,2.3c9.7,1.4,17.4,9.1,18.8,18.7l2.3,0.8C101,17.7,91.8,8.6,80.3,7.4z M53.5,29.2l2.3-0.8 c1.4-9.7,9.1-17.3,18.8-18.7l0.8-2.3C63.9,8.5,54.7,17.7,53.5,29.2z M99.9,34.9c-1.4,9.7-9.1,17.4-18.8,18.8L80.3,56 c11.6-1.1,20.8-10.4,21.9-21.9L99.9,34.9z M74.7,53.7c-9.7-1.4-17.5-9.1-18.8-18.8l-2.3-0.8C54.6,45.6,63.9,54.9,75.5,56 L74.7,53.7z" />
                  </g>
                </g>
                <g>
                  <path class="st1"
                    d="M88.1,77.2c0-4.7,3.3-8.6,7.9-8.6c2.8,0,5.2,1.5,6.3,4.1l-1.8,1.1c-0.9-2.3-2.6-3.3-4.6-3.3 c-3.2,0-5.3,2.7-5.3,6.7c0,3.9,2.1,6.7,5.3,6.7c2,0,3.7-1,4.6-3.3l1.8,1.1c-1.1,2.7-3.4,4.1-6.3,4.1 C91.4,85.8,88.1,81.8,88.1,77.2z" />
                  <path class="st1"
                    d="M110.5,77.2c0-4.7,3.3-8.6,7.7-8.6s7.7,3.9,7.7,8.6c0,4.7-3.3,8.6-7.7,8.6S110.5,81.8,110.5,77.2z M123.5,77.2c0-3.9-2.2-6.7-5.3-6.7c-3.1,0-5.3,2.7-5.3,6.7c0,3.9,2.2,6.7,5.3,6.7C121.3,83.8,123.5,81.1,123.5,77.2z" />
                  <path class="st1" d="M135.5,68.8h2.1l8.3,13v-13h2.1v16.7h-2.1l-8.3-13v13h-2.1V68.8z" />
                  <path class="st1" d="M158.5,68.8h2.1l8.3,13v-13h2.1v16.7h-2.1l-8.3-13v13h-2.1V68.8z" />
                  <path class="st1" d="M181.5,68.8h10v1.8h-7.7V76h7v1.8h-7v5.9h7.7v1.8h-10V68.8z" />
                  <path class="st1"
                    d="M200.1,77.2c0-4.7,3.3-8.6,7.9-8.6c2.8,0,5.2,1.5,6.3,4.1l-1.9,1.1c-0.9-2.3-2.6-3.3-4.6-3.3 c-3.2,0-5.3,2.7-5.3,6.7c0,3.9,2.1,6.7,5.3,6.7c2,0,3.7-1,4.6-3.3l1.9,1.1c-1.1,2.7-3.4,4.1-6.3,4.1 C203.4,85.8,200.1,81.8,200.1,77.2z" />
                  <path class="st1" d="M226.9,70.6h-5.2v-1.8h12.7v1.8h-5.2v14.9h-2.3V70.6z" />
                </g>
              </g>
            </svg>
          </div>

          <div class="text-muted" style="position:fixed;right:3px;bottom:3px;display: none !important;"
            id="sdk-version">
          </div>
          <div class="container">
            <form id="form-authenticate">
              <h1 class="c-join">Join your meeting</h1>
              <!-- <h1 class="c-meeting-soon">The meeting will available soon.</h1> -->
              <div class="row mt-3" style="display: none;">
                <label for="inputMeeting" class="sr-only">Meeting Title</label>
                <input type="name" id="inputMeeting" class="form-control" placeholder="Meeting Title" required
                  autofocus>
              </div>
              <div class="row mt-3" style="display: none;">
                <label for="inputName" class="sr-only">Your Name</label>
                <input type="name" id="inputName" class="form-control" placeholder="Your Name" required>
              </div>
              <div class="row mt-3" style="display: none;">
                <label for="inputRegion" class="sr-only">Media Region</label>
                <select id="inputRegion" class="custom-select" style="width:100%">
                  <option value="us-east-1" selected>United States (N. Virginia)</option>
                  <option value="ap-northeast-1">Japan (Tokyo)</option>
                  <option value="ap-southeast-1">Singapore</option>
                  <option value="af-south-1">South Africa (Cape Town)</option>
                  <option value="eu-south-1">Italy (Milan)</option>
                  <option value="ap-south-1">India (Mumbai)</option>
                  <option value="ap-northeast-2">South Korea (Seoul)</option>
                  <option value="ap-southeast-2">Australia (Sydney)</option>
                  <option value="ca-central-1">Canada</option>
                  <option value="eu-central-1">Germany (Frankfurt)</option>
                  <option value="eu-north-1">Sweden (Stockholm)</option>
                  <option value="eu-west-1">Ireland</option>
                  <option value="eu-west-2">United Kingdom (London)</option>
                  <option value="eu-west-3">France (Paris)</option>
                  <option value="sa-east-1">Brazil (São Paulo)</option>
                  <option value="us-east-2">United States (Ohio)</option>
                  <option value="us-west-1">United States (N. California)</option>
                  <option value="us-west-2">United States (Oregon)</option>
                </select>
              </div>
              <div class="row mt-3" style="display: none;">
                <div class="col-12">
                  <fieldset>
                    <legend>Choose your optional features</legend>
                    <div class="custom-control" style="text-align: left;">
                      <label for="logLevel">Choose a Log Level:</label>
                      <select name="logLevel" id="logLevelSelect">
                        <option value="INFO">INFO</option>
                        <option value="DEBUG">DEBUG</option>
                        <option value="WARN">WARN</option>
                        <option value="ERROR">ERROR</option>
                        <option value="OFF">OFF</option>
                      </select>
                    </div>
                    <div class="custom-control custom-checkbox" style="text-align: left;">
                      <input type="checkbox" id="webaudio" class="custom-control-input">
                      <label for="webaudio" class="custom-control-label">Use WebAudio</label>
                    </div>
                    <div class="custom-control custom-checkbox" style="text-align: left;">
                      <input type="checkbox" id="fullband-speech-mono-quality" class="custom-control-input">
                      <label for="fullband-speech-mono-quality" class="custom-control-label">Set fullband speech (mono)
                        quality</label>
                    </div>
                    <div class="custom-control custom-checkbox" style="text-align: left;">
                      <input type="checkbox" id="fullband-music-mono-quality" class="custom-control-input">
                      <label for="fullband-music-mono-quality" class="custom-control-label">Set fullband music (mono)
                        quality</label>
                    </div>
                    <div class="custom-control custom-checkbox" style="text-align: left;">
                      <input type="checkbox" id="simulcast" class="custom-control-input">
                      <label for="simulcast" class="custom-control-label">Enable Simulcast for Chrome</label>
                    </div>
                    <div class="custom-control custom-checkbox" style="text-align: left;">
                      <input type="checkbox" id="planB" class="custom-control-input">
                      <label for="planB" class="custom-control-label">Disable Unified Plan for Chrome</label>
                    </div>
                    <div class="custom-control custom-checkbox" style="text-align: left;">
                      <input type="checkbox" id="priority-downlink-policy" class="custom-control-input">
                      <label for="priority-downlink-policy" class="custom-control-label">Use Priority-Based Downlink
                        Policy</label>
                      <select id="priority-downlink-policy-preset" class="custom-select"
                        style="width:100%; display: none;">
                        <option value="default" selected>Default</option>
                        <option value="stable">Stable Network</option>
                        <option value="unstable">Unstable Network</option>
                      </select>
                    </div>
                    <div class="custom-control custom-checkbox" style="text-align: left;">
                      <input type="checkbox" checked id="preconnect" class="custom-control-input">
                      <label for="preconnect" class="custom-control-label">Open signaling connection early</label>
                    </div>
                    <div class="custom-control custom-checkbox" style="text-align: left;">
                      <input type="checkbox" id="event-reporting" class="custom-control-input">
                      <label for="event-reporting" class="custom-control-label">Enable event reporting</label>
                    </div>
                    <div class="custom-control custom-checkbox" style="text-align: left;">
                      <input type="checkbox" id="die" class="custom-control-input">
                      <label for="die" class="custom-control-label">Abort on fatal errors</label>
                    </div>
                  </fieldset>
                </div>
              </div>
              <div class="row mt-3" style=" place-content: center; ">
                <button id="authenticate" style="width: 80%;" class="btn btn-lg btn-primary btn-block"
                  type="submit">Continue</button>
              </div>
              <div class="row mt-3" style="display: none;">
                <p>Anyone with access to the meeting link can join.</p>
              </div>
              <a style="display: none;" id="to-sip-flow" class="row mt-3" href="#">Joining via SIP? Click here.</a>
              <div class="row mt-3" style=" place-content: center; ">
                <div id="progress-authenticate" class="w-100 progress progress-hidden" style="width: 80%;">
                  <div class="w-100 progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                    aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <!-- Authenticate for SIP with meeting and voice connector ID -->

        <div id="flow-sip-authenticate" class="flow text-center">
          <div class="container">
            <form id="form-sip-authenticate">
              <h1 class="h3 mb-3 font-weight-normal">Join a meeting via SIP</h1>
              <div class="row mt-3">
                <label for="sip-inputMeeting" class="sr-only">Meeting Title</label>
                <input type="name" id="sip-inputMeeting" class="form-control" placeholder="Meeting Title" required
                  autofocus>
              </div>
              <div class="row mt-3">
                <label for="voiceConnectorId" class="sr-only">Voice Connector ID</label>
                <input type="name" id="voiceConnectorId" class="form-control" placeholder="Voice Connector ID" required>
              </div>
              <div class="row mt-3">
                <button id="button-sip-authenticate" class="btn btn-lg btn-primary btn-block"
                  type="submit">Continue</button>
              </div>
              <div class="row mt-3">
                <p>You will need a SIP client in order to join the meeting.</p>
              </div>
            </form>
          </div>
        </div>

        <!-- Failure card if meeting is invalid -->

        <div id="flow-failed-meeting" class="flow flow text-center p-2">
          <div class="container">
            <form id="form-failed-meeting">
              <div class="card border-warning mb-3" style="max-width: 20rem;">
                <div style="display: none;" id="failed-meeting" class="card-header"></div>
                <div style="display: none;" class="card-body">
                  <h4 class="card-title">Unable to find meeting</h4>
                  <p class="card-text">There was an issue finding that meeting. The meeting may have already ended, or
                    your
                    authorization may have expired.</p>
                  <small id="failed-meeting-error" class="text-muted"></small>
                </div>

                <div class="card-body">
                  <h4 class="card-title">Something went wrong.</h4>
                  <p class="card-text">Please refresh this page</p>
                </div>

              </div>
              <button style="display: none;" class="btn btn-lg btn-outline-warning btn-block" type="submit">OK</button>
            </form>
          </div>
        </div>

        <!-- Prompt for permission to get access to device labels -->

        <div id="flow-need-permission" class="flow">
          <div class="container">
            <form id="form-need-permission">
              <div class="card border-info mb-3" style="max-width: 20rem;">
                <div class="card-header">Permissions check</div>
                <div class="card-body">
                  <h4 class="card-title">Unable to get device labels</h4>
                  <p class="card-text">In order to select media devices, we need to do a quick permissions check of your
                    mic
                    and camera. When the pop-up appears, choose <b>Allow</b>.</p>
                </div>
              </div>
            </form>
          </div>
        </div>

        <!-- Display SIP URI -->

        <div id="flow-sip-uri" class="flow">
          <div class="container">
            <form id="sip-uri-form">
              <div class="card border-info mb-3" style="max-width: 20rem;">
                <div class="card-header">SIP URI</div>
                <div class="card-body">
                  <a id="copy-sip-uri" class="card-title" href="#">Copy</a>
                  <input id="sip-uri" class="card-text">
                </div>
              </div>
            </form>
          </div>
        </div>

        <!-- <div id="flow-permission-save-transcribe" class="flow flow text-center p-2">
    <div class="container">
      <form id="form-permission-save-transcribe">
        <div  class="card border-primary mb-3" style="max-width: 20rem;">
          <div id="failed-meeting" class="card-header"></div>
          <div class="card-body">
            <input type="hidden" name="permission-save-choice" id="permission-save-choice">
            <h4 class="card-title">Permission</h4>
            <p class="card-text">Do you want to save the conversation?</p> 
          </div>  
          
          <div class="card-body"> 
            <button  class="btn btn-outline-primary"  type="submit"
            onclick="onPermissionSaveTrans('yes')">Yes</button>
            <button   class="btn btn-outline-primary"  type="submit" 
            onclick="onPermissionSaveTrans('no')">No</button>
          </div>
  
        </div>
      </form>
    </div> 
  </div> -->

        <div class="modal" tabindex="-1" role="dialog" id="flow-permission-save-transcribe">

          <form id="form-permission-save-transcribe">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="c-message-cc">The patient has turned on closed captioning.</h5>
                  <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button> -->
                </div>
                <div class="modal-footer">
                  <input type="hidden" name="permission-save-choice" id="permission-save-choice">
                  <button type="submit" class="btn btn-primary" (click)="onPermissionSaveTrans('yes')">Ok</button>
                  <!-- <button type="submit" class="btn btn-secondary" data-dismiss="modal"  
            onclick="onPermissionSaveTrans('no')">No</button> -->
                </div>
              </div>
            </div>
          </form>

        </div>

        <div class="modal" tabindex="-1" role="dialog" style="z-index: 2" id="flow-reminder-recorded">

          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Record Reminder</h5>
              </div>
              <div class="modal-body">
                <p>The meeting is recorded.</p>
              </div>
              <div class="modal-footer">
                <button type="submit" class="btn btn-primary" data-dismiss="modal"
                  onclick="document.getElementById('flow-reminder-recorded').style.display = 'none'">Ok</button>
              </div>
            </div>
          </div>

        </div>

        <div class="modal" tabindex="-1" role="dialog" id="flow-reminder-cc">

          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Closed Captions Enabled</h5>
              </div>
              <div class="modal-footer">
                <button type="submit" class="btn btn-primary" data-dismiss="modal"
                  onclick="document.getElementById('flow-reminder-cc').style.display = 'none'">Ok</button>
              </div>
            </div>
          </div>

        </div>


        <div class="modal" tabindex="-1" role="dialog" id="more-action">
          <div class="modal-dialog" role="document" style="max-width: 220px;">
            <div class="modal-content">
              <div class="row ">
                <div class="col-12 text-center c-btn" style="display: none;">
                  <div id="action-share-screen" class="c-action">
                    <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="96.000000pt" height="96.000000pt"
                      viewBox="0 0 96.000000 96.000000" preserveAspectRatio="xMidYMid meet">
                      <g transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)" stroke="none">
                        <path
                          d="M140 820 c-19 -19 -20 -33 -20 -340 0 -307 1 -321 20 -340 19 -19 33 -20 340 -20 307 0 321 1 340 20 19 19 20 33 20 340 0 307 -1 321 -20 340 -19 19 -33 20 -340 20 -307 0 -321 -1 -340 -20z m620 -340 l0 -280 -280 0 -280 0 0 280 0 280 280 0 280 0 0 -280z" />
                        <path
                          d="M375 580 l-100 -100 83 0 82 0 0 -100 0 -100 40 0 40 0 0 100 0 100 82 0 83 0 -100 100 c-55 55 -102 100 -105 100 -3 0 -50 -45 -105 -100z" />
                      </g>
                    </svg>
                    <p>Share screen</p>
                  </div>
                </div>
                <div class="col-12 text-center  c-btn">
                  <div id="action-chat" class="c-action">
                    <svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96.000000 96.000000"
                      preserveAspectRatio="xMidYMid meet">
                      <g transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)" stroke="none">
                        <path
                          d="M105 815 l-25 -24 0 -236 c0 -275 1 -278 79 -201 l47 46 192 0 c257 0 242 -14 242 220 0 168 0 171 -25 195 l-24 25 -231 0 -231 0 -24 -25z" />
                        <path
                          d="M718 531 c-3 -118 -12 -141 -72 -185 -24 -19 -44 -21 -177 -24 l-149 -3 0 -35 c0 -24 8 -43 25 -59 24 -25 25 -25 223 -25 l199 0 42 -46 c23 -25 47 -44 54 -41 9 3 13 63 15 241 l2 237 -24 24 c-21 21 -35 25 -80 25 l-54 0 -4 -109z" />
                      </g>
                    </svg>
                    <p>In call messages</p>
                  </div>
                </div>
                <div class="col-6 text-center  c-btn" style="display: none !important;">
                  <div id="action-toggle-caption" class="c-action">
                    <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="90.000000pt" height="90.000000pt"
                      viewBox="0 0 90.000000 90.000000" preserveAspectRatio="xMidYMid meet">
                      <g transform="translate(0.000000,90.000000) scale(0.100000,-0.100000)" fill="#000000"
                        stroke="none">
                        <path
                          d="M72 738 c-17 -17 -17 -559 0 -576 17 -17 739 -17 756 0 17 17 17 559 0 576 -17 17 -739 17 -756 0z m304 -152 c38 -38 42 -54 18 -63 -11 -4 -23 3 -36 20 -15 21 -28 27 -57 27 -55 0 -61 -12 -61 -120 0 -108 6 -120 61 -120 29 0 42 6 57 27 13 17 25 24 36 20 24 -9 20 -25 -18 -63 -73 -73 -175 -28 -191 84 -18 132 29 222 115 222 34 0 48 -6 76 -34z m300 0 c38 -38 42 -54 18 -63 -11 -4 -23 3 -36 20 -15 21 -28 27 -57 27 -55 0 -61 -12 -61 -120 0 -108 6 -120 61 -120 29 0 42 6 57 27 13 17 25 24 36 20 24 -9 20 -25 -18 -63 -28 -28 -42 -34 -76 -34 -34 0 -48 6 -76 34 l-34 34 0 102 0 102 34 34 c28 28 42 34 76 34 34 0 48 -6 76 -34z" />
                      </g>
                    </svg>
                    <p>Toggle captions</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal" tabindex="-1" role="dialog" id="chat-box-modal">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Chat box</h5>
                <button type="button" class="close" (click)="onCloseModal('chat-box-modal')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body" style="padding: 8px;">
                <div style=" padding-bottom: 0px;">
                  <div class="message d-flex flex-column" style="flex: 1 1 auto; overflow: hidden;  min-height: 210px;">
                    <div class="list-group receive-message" id="receive-message1" style="flex: 1 1 auto; overflow-y: auto;
                border: 1px solid rgba(0, 0, 0, 0.125); background-color: #fff; max-height: 350px;overflow: auto;">
                    </div>
                    <div class="input-group send-message" style="display:flex;flex:0 0 auto;margin-top:0.2rem">
                      <textarea class="form-control shadow-none" id="send-message1" rows="1"
                        placeholder="Type a message (markdown supported)" style="display:inline-block; width:100%;
                  resize:none; border-color: rgba(0, 0, 0, 0.125); outline: none; padding-left: 1.4rem"></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal" tabindex="-1" role="dialog" id="endcall-confirmation">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">End Call</h5>
                <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button> -->
              </div>
              <div class="modal-body">
                <p>Do you want to end this call?</p>
              </div>
              <div class="modal-footer">
                <!-- <button type="button" class="btn btn-danger"
          (click)="onEndCall('yesandsurvey')">Take the Survey</button> -->
                <button type="button" class="btn btn-danger" (click)="onEndCall('yes')">Yes</button>
                <button type="button" class="btn btn-secondary" data-dismiss="modal"
                  (click)="onEndCall('no')">No</button>
              </div>
            </div>
          </div>
        </div>
        <!-- Device management and preview screen -->

        <div id="flow-devices" class="flow" style="width: 100%;    max-width: 550px;">
          <div class="container">
            <form id="form-devices">
              <h1 class="h3 mb-3 font-weight-normal text-center">Select devices</h1>
              <div class="row mt-3">
                <div class="col-12 text-center d-sm-block video-preview">
                  <video id="video-preview" class="" style="border-radius:8px  ;  width: 100%;"></video>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12">
                  <label for="audio-input block">Microphone</label>
                  <select id="audio-input" class="custom-select" style="width:100%"></select>
                </div>

                <div class="text-center col-12 d-sm-block">
                  <label>Preview</label>
                  <div class="w-100 progress" style="margin-top:0.75rem">
                    <div id="audio-preview" class="progress-bar bg-success" role="progressbar" aria-valuenow="0"
                      aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div id="voice-focus-setting" class="col-12 hidden">
                  <input autocomplete="off" type="checkbox" id="add-voice-focus">
                  <label style="margin-left: 0.5em" for="voice-focus-setting block">Add Voice Focus</label>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-lg-6 col-s-6">
                  <label for="video-input block">Camera</label>
                  <select id="video-input" class="custom-select" style="width:100%"></select>
                </div>

                <div class="col-lg-6 col-s-6">
                  <label for="video-input-quality block">Resolution</label>
                  <select id="video-input-quality" class="custom-select" style="width:100%">
                    <option value="360p">360p (nHD) @ 15 fps (600 Kbps max)</option>
                    <option value="540p" selected>540p (qHD) @ 15 fps (1.4 Mbps max)</option>
                    <option value="720p">720p (HD) @ 15 fps (1.4 Mbps max)</option>
                  </select>
                </div>
              </div>

              <div class="row mt-3" id="video-input-filter-container">
                <div class="col-lg-6 col-s-6" style="display: none;">
                  <label for="video-input-filter block">Video Filter</label>
                  <select id="video-input-filter" class="custom-select" style="width:100%">
                    <option value="None" selected>None</option>
                  </select>
                </div>
                <div class="col-lg-6 col-s-6">
                  <label for="audio-output block">Speaker</label>
                  <select id="audio-output" class="custom-select" style="width:100%"></select>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12">
                  <button id="button-test-sound" class="btn btn-outline-secondary btn-block h-50 d-sm-block"
                    style="margin-top:2rem">Test Speaker</button>
                </div>
              </div>

              <div class="row mt-3" style="padding-bottom: 60px;">
                <div class="col-lg">
                  <button id="joinButton" class="btn btn-lg btn-primary btn-block" type="submit">Join</button>
                </div>
              </div>
              <div class="row mt-3" style="display: none !important;">
                <div class="col-lg">
                  <p>Ready to join meeting <b><span id="info-meeting"></span></b> as <b><span
                        id="info-name"></span></b>.
                  </p>
                </div>
              </div>
            </form>
            <div id="progress-join" class="w-100 progress progress-hidden">
              <div class="w-100 progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>
        </div>

        <!-- In-meeting screen -->

        <div id="flow-meeting" class="flow" style="left:0;top:0;bottom:55px;right:0;height: 100%;width: 100%;">
          <div id="live-transcription-modal" class="modal">
            <div id="live-transcription-modal-content" class="modal-content">
              <div class="modal-content-header">
                <span></span>
                <span class="modal-title text-muted">Live Transcription Configuration</span>
                <span id="button-live-transcription-modal-close" class="close">&times;</span>
              </div>
              <div id="engine-option-group">
                <input type="radio" id="engine-transcribe" name="transcription-engine" value="engine-transcribe"
                  checked=true>
                <label for="engine-transcribe">Amazon Transcribe</label><br>
                <input type="radio" id="engine-transcribe-medical" name="transcription-engine"
                  value="engine-transcribe-medical">
                <label for="engine-transcribe-medical">Amazon Transcribe Medical</label><br>
              </div>
              <div id="engine-language-group">
                <div id="engine-transcribe-language">
                  <label for="transcribe-language" class="sr-only">Language</label>
                  <select id="transcribe-language" class="custom-select" style="width:100%">
                    <option value="en-US" selected>US English (en-US)</option>
                    <option value="en-GB">British English (en-GB)</option>
                    <option value="en-AU">Australian English (en-AU)</option>
                    <option value="es-US">US Spanish (es-US)</option>
                    <option value="fr-CA">Canadian French (fr-CA)</option>
                    <option value="fr-FR">French (fr-FR)</option>
                    <option value="it-IT">Italian (it-IT)</option>
                    <option value="de-DE">German (de-DE)</option>
                    <option value="pt-BR">Brazilian Portuguese (pt-BR)</option>
                    <option value="ja-JP">Japanese (ja-JP)</option>
                    <option value="ko-KR">Korean (ko-KR)</option>
                    <option value="zh-CN">Mandarin Chinese - Mainland (zh-CN)</option>
                  </select>
                </div>
                <div id="engine-transcribe-medical-language" class="hidden">
                  <label for="transcribe-medical-language" class="sr-only">Language</label>
                  <select id="transcribe-medical-language" class="custom-select" style="width:100%">
                    <option value="en-US" selected>US English (en-US)</option>
                  </select>
                </div>
              </div>
              <button id="button-start-transcription" type="button" class="btn btn-success mx-1 mx-xl-2 my-2"
                title="Start" data-toggle="button" aria-pressed="false" autocomplete="off">
                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8">
                  <path d="M0 0v8l4-4-4-4z" transform="translate(2)" />
                </svg>
              </button>
            </div>
          </div>
          <div class="p-2 d-none d-sm-block align-items-end"
            style="position:fixed;right:0;bottom:0;left:0;display:none !important;">
            <div class="row align-items-end">
              <div class="col">
                <div id="chime-meeting-id" class="text-muted"></div>
                <div id="desktop-attendee-id" class="text-muted"></div>
              </div>
              <div class="col d-none d-lg-block">
                <div id="video-uplink-bandwidth" class="text-muted text-right"></div>
                <div id="video-downlink-bandwidth" class="text-muted text-right"></div>
              </div>
            </div>
          </div>
          <audio id="meeting-audio" style="display:none"></audio>
          <div id="meeting-container" class=" h-100" style="display:grid;">


            <div style="display: none;" class="col-12 col-lg-3 order-1 order-lg-1 text-center text-lg-left">
              <div id="meeting-id" class="navbar-brand text-muted m-0 m-lg-2"></div>
              <div id="mobile-chime-meeting-id" class="text-muted d-block d-sm-none" style="font-size:0.65rem;"></div>
              <div id="mobile-attendee-id" class="text-muted d-block d-sm-none mb-2" style="font-size:0.65rem;"></div>
            </div>

            <!-- <div class="col-12 text-right c-container-action" >  -->
            <div id="buttons-minor" class="text-right"
              style="height: inherit;  padding-block: 5px; display: flex; justify-content: space-around; align-items: flex-start; grid-area: one">

              <div class="btn-with-label">
                <div class="btn-group mx-1 mx-xl-2 mt-2" role="group" aria-label="Toggle speaker">
                  <button id="button-speaker" type="button" class="btn btn-success btn-sm c-btn-group-2-1 "
                    title="Toggle speaker">
                    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8">
                      <path
                        d="M3.34 0l-1.34 2h-2v4h2l1.34 2h.66v-8h-.66zm1.66 3v2c.09 0 .18-.01.25-.03.43-.11.75-.51.75-.97 0-.46-.31-.86-.75-.97-.08-.02-.17-.03-.25-.03z"
                        transform="translate(1)" />
                    </svg>
                  </button>
                  <div class="btn-group" role="group">
                    <button id="button-speaker-drop" type="button"
                      class="btn btn-success dropdown-toggle btn-sm c-btn-group " data-toggle="dropdown"
                      aria-haspopup="true" aria-expanded="false" title="Select speaker">

                    </button>
                    <div id="dropdown-menu-speaker" class="dropdown-menu dropdown-menu-right btn-sm"
                      aria-labelledby="button-speaker-drop" x-placement="bottom-start"
                      style="position: absolute; transform: translate3d(0px, 38px, 0px); top: 0px; left: 0px; will-change: transform;">
                      <a class="dropdown-item" href="#">Default speaker</a>
                    </div>
                  </div>
                </div>
                <span class="c-btn-group">Speaker</span>
              </div>
              <div class="btn-with-label">
                <button id="button-chat" type="button" class="hide-md-sm btn btn-success mx-1 mx-xl-2 mt-2 btn-sm"
                  title="Toggle chat">
                  <svg id="Icon_material-chat-bubble" data-name="Icon material-chat-bubble"
                    xmlns="http://www.w3.org/2000/svg" width="36.413" height="28.272" viewBox="0 0 36.413 28.272">
                    <path id="Icon_material-chat-bubble-2" data-name="Icon material-chat-bubble"
                      d="M35.772,3H6.641C4.639,3,3,4.272,3,5.827V31.272l7.283-5.654H35.772c2,0,3.641-1.272,3.641-2.827V5.827C39.413,4.272,37.775,3,35.772,3Z"
                      transform="translate(-3 -3)" />
                  </svg>
                </button>
                <span class="c-btn-group">Show Chat</span>
              </div>
              <div class="btn-group mx-1 mx-xl-2 my-2" role="group" aria-label="Apply filter" style="display: none;">
                <button id="button-video-filter" type="button" class="btn btn-success btn-sm" title="Apply filter">
                  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8">
                    <path
                      d="M4 0c-2.2 0-4 1.8-4 4s1.8 4 4 4 4-1.8 4-4-1.8-4-4-4zm0 1c1.66 0 3 1.34 3 3s-1.34 3-3 3v-6z" />
                  </svg>
                </button>
                <div class="btn-group" role="group">
                  <button id="button-video-filter-drop" type="button" class="btn btn-success dropdown-toggle btn-sm"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="Select filter"></button>
                  <div id="dropdown-menu-filter" class="dropdown-menu dropdown-menu-right btn-sm"
                    aria-labelledby="button-video-filter-drop" x-placement="bottom-start">
                    <a class="dropdown-item" href="#">No filter</a>
                  </div>
                </div>
              </div>
              <div style="display: none;" class="btn-group mx-1 mx-xl-2 my-2" role="group"
                aria-label="Toggle share screen">
                <!-- <button id="button-content-share" type="button" class="btn btn-success" title="Toggle content share">
              <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8">
              <path d="M4.09 0c-.05 0-.1.04-.13.09l-.94 1.81c-.02.05-.07.09-.13.09h-1.41c-.83 0-1.5.67-1.5 1.5v4.41c0 .05.04.09.09.09h7.81c.05 0 .09-.04.09-.09v-5.81c0-.06-.04-.09-.09-.09h-.81c-.05 0-.1-.04-.13-.09l-.94-1.81c-.03-.05-.07-.09-.13-.09h-1.81zm-2.59 3c.28 0 .5.22.5.5s-.22.5-.5.5-.5-.22-.5-.5.22-.5.5-.5zm3.5 0c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zm0 1c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1z"
              />
            </svg>
            </button> -->
                <div class="btn-group" role="group" style="display: none;">
                  <button id="button-content-share-drop" type="button" class="btn btn-success dropdown-toggle btn-sm"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                    title="Select content to share"></button>
                  <div id="dropdown-menu-content-share" class="dropdown-menu dropdown-menu-right btn-sm"
                    aria-labelledby="button-content-share-drop" x-placement="bottom-start"
                    style="position: absolute; transform: translate3d(0px, 38px, 0px); top: 0px; left: 0px; will-change: transform;">
                    <a id="dropdown-item-content-share-screen-capture" class="dropdown-item" href="#">Screen
                      Capture...</a>
                    <a id="dropdown-item-content-share-screen-test-video" class="dropdown-item" href="#">Test Video</a>
                    <a id="dropdown-item-content-share-file-item" class="dropdown-item" href="#"><input
                        id="content-share-item" type="file"></a>
                    <a id="dropdown-item-content-share-stop" style="display:none" class="dropdown-item" href="#">Stop
                      Sharing</a>
                  </div>
                </div>
              </div>
              <div class="btn-with-label">
                <button id="button-content-share" type="button"
                  class="hide-md-sm btn btn-success mx-1 mx-xl-2 mt-2 btn-sm" title="Toggle content share">
                  <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="96.000000pt" height="96.000000pt"
                    viewBox="0 0 96.000000 96.000000" preserveAspectRatio="xMidYMid meet">
                    <g transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)" stroke="none">
                      <path
                        d="M140 820 c-19 -19 -20 -33 -20 -340 0 -307 1 -321 20 -340 19 -19 33 -20 340 -20 307 0 321 1 340 20 19 19 20 33 20 340 0 307 -1 321 -20 340 -19 19 -33 20 -340 20 -307 0 -321 -1 -340 -20z m620 -340 l0 -280 -280 0 -280 0 0 280 0 280 280 0 280 0 0 -280z" />
                      <path
                        d="M375 580 l-100 -100 83 0 82 0 0 -100 0 -100 40 0 40 0 0 100 0 100 82 0 83 0 -100 100 c-55 55 -102 100 -105 100 -3 0 -50 -45 -105 -100z" />
                    </g>
                  </svg>
                </button>
                <span class="c-btn-group">Share Screen</span>
              </div>
              <button style="display: none;" id="button-pause-content-share" type="button"
                class="btn btn-success mx-1 mx-xl-2 my-2 btn-sm" title="Pause and unpause content share">
                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8"></svg>
                <path d="M0 0v6h2v-6h-2zm4 0v6h2v-6h-2z" transform="translate(1 1)"></path>
              </button>

              <!-- <button id="button-live-transcription" type="button" class="btn btn-success mx-1 mx-xl-2 my-2 btn-sm" title="Toggle live transcription display"  
          style="    padding-top: 1px;
          padding-bottom: 1px;
          padding-left: 6px;
          padding-right: 6px;" > 
          <svg xmlns="http://www.w3.org/2000/svg"
          style="width: 25pt;
          height: 25pt;" version="1.0"  viewBox="0 0 50.000000 50.000000" preserveAspectRatio="xMidYMid meet">
            <g transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)"  stroke="none">
            <path d="M91 356 c-35 -19 -50 -54 -51 -113 0 -70 27 -103 92 -110 42 -5 50 -3 78 25 38 38 40 72 5 72 -18 0 -25 -5 -25 -20 0 -28 -36 -45 -66 -31 -75 34 -37 170 40 142 14 -6 26 -17 26 -26 0 -9 9 -15 25 -15 34 0 32 24 -4 61 -33 32 -78 38 -120 15z"/>
            <path d="M314 351 c-80 -49 -61 -197 26 -216 49 -11 89 3 111 38 25 43 24 57 -6 57 -15 0 -25 -6 -25 -15 0 -8 -11 -22 -25 -31 -23 -15 -27 -15 -50 0 -44 29 -46 102 -2 131 27 18 64 10 72 -16 4 -12 16 -19 31 -19 22 0 24 3 19 23 -18 61 -91 84 -151 48z"/>
            </g>
          </svg>
          </button>  -->

              <div class="btn-with-label">
                <button id="button-live-transcription" type="button"
                  class="hide-md-sm btn btn-success mx-1 mx-xl-2 mt-2 btn-sm" title="Toggle live transcription display">
                  <svg xmlns="http://www.w3.org/2000/svg" version="1.0" viewBox="0 0 50.000000 50.000000"
                    preserveAspectRatio="xMidYMid meet">
                    <g transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)" stroke="none">
                      <path
                        d="M91 356 c-35 -19 -50 -54 -51 -113 0 -70 27 -103 92 -110 42 -5 50 -3 78 25 38 38 40 72 5 72 -18 0 -25 -5 -25 -20 0 -28 -36 -45 -66 -31 -75 34 -37 170 40 142 14 -6 26 -17 26 -26 0 -9 9 -15 25 -15 34 0 32 24 -4 61 -33 32 -78 38 -120 15z" />
                      <path
                        d="M314 351 c-80 -49 -61 -197 26 -216 49 -11 89 3 111 38 25 43 24 57 -6 57 -15 0 -25 -6 -25 -15 0 -8 -11 -22 -25 -31 -23 -15 -27 -15 -50 0 -44 29 -46 102 -2 131 27 18 64 10 72 -16 4 -12 16 -19 31 -19 22 0 24 3 19 23 -18 61 -91 84 -151 48z" />
                    </g>
                  </svg>
                </button>
                <span class="c-btn-group">Closed<br>Captioning</span>
              </div>
              <!-- <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8">
            <path d="M4.5 0c-1.21 0-2.27.86-2.5 2-1.1 0-2 .9-2 2 0 .37.11.71.28 1h2.22l2-2 2 2h1.41c.06-.16.09-.32.09-.5 0-.65-.42-1.29-1-1.5v-.5c0-1.38-1.12-2.5-2.5-2.5zm0 4.5l-2.5 2.5h2v.5a.5.5 0 1 0 1 0v-.5h2l-2.5-2.5z" />
          </svg> -->
              <div class="btn-with-label" style="display: none;">
                <button id="button-record-cloud" type="button" class="btn btn-success mx-1 mx-xl-2 mt-2 btn-sm"
                  title="Record meeting" data-toggle="button" aria-pressed="false" autocomplete="off">
                  <svg>
                    <circle id="rec-svg" cx="10" cy="10" r="8" fill="red"></circle>
                  </svg>
                </button>
                <span>Record</span>
              </div>

              <button id="button-more-action" type="button" class="show-md-sm btn btn-light mx-1 mx-xl-2 my-2"
                style=" padding: 5px 9px 5px 9px;display: none; " title="Toggle more actions">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="96.000000pt" height="96.000000pt"
                  viewBox="0 0 96.000000 96.000000" preserveAspectRatio="xMidYMid meet">
                  <g transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
                    <path d="M404 827 c-3 -8 -4 -43 -2 -78 l3 -64 75 0 75 0 0 75 0 75 -73 3 c-55 2 -74 -1 -78 -11z">
                    </path>
                    <path d="M404 547 c-3 -8 -4 -43 -2 -78 l3 -64 75 0 75 0 0 75 0 75 -73 3 c-55 2 -74 -1 -78 -11z">
                    </path>
                    <path d="M404 267 c-3 -8 -4 -43 -2 -78 l3 -64 75 0 75 0 0 75 0 75 -73 3 c-55 2 -74 -1 -78 -11z">
                    </path>
                  </g>
                </svg>
              </button>



              <button style="display: none;" id="button-video-stats" type="button"
                class="btn btn-success mx-1 mx-xl-2 my-2 btn-sm" title="Toggle video WebRTC stats display"
                data-toggle="button" aria-pressed="false" autocomplete="off">
                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8">
                  <path d="M6 0v8h1v-8h-1zm-2 1v7h1v-7h-1zm-2 2v5h1v-5h-1zm-2 2v3h1v-3h-1z" />
                </svg>
              </button>
              <button style="display: none;" id="button-record-self" type="button"
                class="btn btn-success mx-1 mx-xl-2 my-2 btn-sm" title="Record my audio and video" data-toggle="button"
                aria-pressed="false" autocomplete="off">
                <svg>
                  <circle id="rec-svg" cx="10" cy="10" r="8" fill="red"></circle>
                </svg>
              </button>
            </div>


            <div id="buttons-major" class="text-right"
              style="height: inherit; padding-block: 5px;  display: flex; justify-content: space-around; align-items: flex-start; grid-area: two">

              <div class="btn-with-label">
                <div class="btn-group mx-1 mx-xl-2 mt-2" role="group" aria-label="Toggle camera"
                  style="position: relative;">
                  <button id="button-camera" type="button" class="btn btn-success btn-sm c-btn-group-1"
                    title="Toggle camera">
                    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8">
                      <path
                        d="M.5 0c-.28 0-.5.23-.5.5v4c0 .28.23.5.5.5h5c.28 0 .5-.22.5-.5v-1.5l1 1h1v-3h-1l-1 1v-1.5c0-.28-.22-.5-.5-.5h-5z"
                        transform="translate(0 1)" />
                    </svg>
                  </button>

                  <div class="btn-group c-btn-group" role="group">
                    <button id="button-camera-drop"
                      style="border-top-right-radius: 3px; border-bottom-right-radius: 3px; " type="button"
                      class="btn btn-success dropdown-toggle btn-sm" data-toggle="dropdown" aria-haspopup="true"
                      aria-expanded="false" title="Select camera">
                      <span>Camera</span>
                    </button>
                    <div id="dropdown-menu-camera" class="dropdown-menu dropdown-menu-right btn-sm"
                      aria-labelledby="button-camera-drop" x-placement="bottom-start"
                      style="position: absolute; transform: translate3d(0px, 38px, 0px); top: 0px; left: 0px; will-change: transform;">
                      <a class="dropdown-item" href="#">Default camera</a>
                    </div>
                  </div>
                  <div id="camera-prompt"
                    style="z-index: 1; width: fit-content; position: absolute; transform: translateX(-40%) translateY(50%); display: flex; flex-direction: column; align-items: center;">
                    <div id="camera-prompt-arrow"
                      style="height: 16px; width: 16px; background-color: #ddd; transform:  translateY(12px) rotate(45deg)">
                    </div>
                    <div style="width: max-content; padding: 20px; background-color: #ddd; border-radius: 10px;">
                      <span>Please
                        turn on your camera</span></div>
                  </div>
                </div>
                <span>Camera</span>
              </div>



              <div class="btn-with-label">
                <div class="btn-group mx-1 mx-xl-2 mt-2" role="group" aria-label="Toggle microphone">
                  <button id="button-microphone" type="button" class="btn btn-success btn-sm c-btn-group-1"
                    title="Toggle microphone">
                    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8">
                      <path
                        d="M2.91-.03a1 1 0 0 0-.13.03 1 1 0 0 0-.78 1v2a1 1 0 1 0 2 0v-2a1 1 0 0 0-1.09-1.03zm-2.56 2.03a.5.5 0 0 0-.34.5v.5c0 1.48 1.09 2.69 2.5 2.94v1.06h-.5c-.55 0-1 .45-1 1h4.01c0-.55-.45-1-1-1h-.5v-1.06c1.41-.24 2.5-1.46 2.5-2.94v-.5a.5.5 0 1 0-1 0v.5c0 1.11-.89 2-2 2-1.11 0-2-.89-2-2v-.5a.5.5 0 0 0-.59-.5.5.5 0 0 0-.06 0z"
                        transform="translate(1)" />
                    </svg>
                  </button>

                  <div class="btn-group c-btn-group" role="group">
                    <button id="button-microphone-drop" type="button" class="btn btn-success dropdown-toggle btn-sm"
                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="Select microphone">
                      <span>Microphone</span>
                    </button>
                    <div id="dropdown-menu-microphone" class="dropdown-menu dropdown-menu-right btn-sm"
                      aria-labelledby="button-microphone-drop" x-placement="bottom-start"
                      style="position: absolute; transform: translate3d(0px, 38px, 0px); top: 0px; left: 0px; will-change: transform;">
                      <a class="dropdown-item" href="#">Default microphone</a>
                    </div>
                  </div>

                </div>
                <span>Microphone</span>
              </div>

              <div class="btn-with-label">
                <button id="button-end-call" type="button"
                  class="btn btn-danger mx-1 mx-xl-2 mt-2 btn-sm c-btn-group-3-1" title="End Call">
                  <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="96.000000pt" height="96.000000pt"
                    viewBox="0 0 96.000000 96.000000" preserveAspectRatio="xMidYMid meet">
                    <g transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)" fill="#FFF" stroke="none">
                      <path
                        d="M315 781 c-79 -23 -158 -64 -222 -115 -69 -55 -69 -75 0 -144 55 -54 85 -64 108 -36 7 9 37 30 66 46 l53 30 0 59 c0 56 2 60 28 70 44 18 181 22 237 7 l50 -13 3 -62 3 -62 52 -29 c29 -16 59 -37 66 -46 7 -9 23 -16 35 -16 28 0 126 98 126 127 0 50 -160 154 -287 187 -82 21 -240 20 -318 -3z" />
                      <path
                        d="M440 420 l0 -100 -67 0 -68 0 80 -80 c44 -44 84 -80 90 -80 6 0 46 36 90 80 l80 80 -63 0 -62 0 0 100 0 100 -40 0 -40 0 0 -100z" />
                    </g>
                  </svg>
                  <span class="button-label c-btn-group">Leave</span>
                </button>
                <span class="c-btn-group">Leave</span>
              </div>


              <div style="display:none" class="col-4 col-lg-3 order-3 order-lg-3 text-right text-lg-right">
                <button id="button-meeting-leave" type="button"
                  class="btn btn-outline-success mx-1 mx-xl-2 my-2 px-4 btn-sm" title="Leave meeting">
                  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8">
                    <path d="M3 0v1h4v5h-4v1h5v-7h-5zm-1 2l-2 1.5 2 1.5v-1h4v-1h-4v-1z" />
                  </svg>
                </button>
                <button id="button-meeting-end" type="button"
                  class="btn btn-outline-danger mx-1 mx-xl-2 my-2 px-4 btn-sm" title="End meeting">
                  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8">
                    <path
                      d="M3 0v4h1v-4h-1zm-1.28 1.44l-.38.31c-.81.64-1.34 1.64-1.34 2.75 0 1.93 1.57 3.5 3.5 3.5s3.5-1.57 3.5-3.5c0-1.11-.53-2.11-1.34-2.75l-.38-.31-.63.78.38.31c.58.46.97 1.17.97 1.97 0 1.39-1.11 2.5-2.5 2.5s-2.5-1.11-2.5-2.5c0-.8.36-1.51.94-1.97l.41-.31-.63-.78z" />
                  </svg>
                </button>
              </div>
            </div>
            <!-- <div id="roster-tile-container" class="row" style="height: 100%;flex: unset;">  -->
            <!-- CON-50 -->
            <div id="roster-tile-container" class="row"
              style="margin-left: 0px;  width: 100%;max-width: 100%; height: 100%; flex-flow: unset; grid-area: three">
              <!-- CON-30 <div id="tile-transcript-container" class="d-flex flex-column col-12 col-sm-12 col-md-12  h-100 col-lg-9" style=" padding: 0px ; padding-left: 10px ; padding-bottom: 10px ; padding-right: 10px ;"> -->
              <div class="main-container">
                <div id="notifs-container" class="notifs-container"></div>
                <div id="temp-tiles" class="temp-tiles-container">
                </div>
                <div id="tile-transcript-container" class="d-flex flex-column"
                  style="flex-grow: 1;padding: 0px;padding-left: 3px;padding-bottom: 10px;padding-right: 3px;display: grid;place-items: center;">
                  <div id="tile-area" class="v-grid">
                    <div id="tile-0" class="video-tile">
                      <video id="video-0" class="video-tile-video"></video>
                      <div id="attendeeid-0" class="video-tile-attendeeid"></div>
                      <div id="nameplate-0" class="video-tile-nameplate"></div>
                      <div id="pause-state-0"></div>
                      <button style="display:none" id="video-pause-0" class="video-tile-pause">Pause</button>
                      <button style="display:none" style="display:none" id="video-pin-0"
                        class="video-tile-pin">Pin</button>
                    </div>
                    <div id="tile-1" class="video-tile">
                      <video id="video-1" class="video-tile-video"></video>
                      <div id="attendeeid-1" class="video-tile-attendeeid"></div>
                      <div id="nameplate-1" class="video-tile-nameplate"></div>
                      <div id="pause-state-1"></div>
                      <button style="display:none" id="video-pause-1" class="video-tile-pause">Pause</button>
                      <button style="display:none" style="display:none" id="video-pin-1"
                        class="video-tile-pin">Pin</button>
                    </div>
                    <div id="tile-2" class="video-tile">
                      <video id="video-2" class="video-tile-video"></video>
                      <div id="attendeeid-2" class="video-tile-attendeeid"></div>
                      <div id="nameplate-2" class="video-tile-nameplate"></div>
                      <div id="pause-state-2"></div>
                      <button style="display:none" id="video-pause-2" class="video-tile-pause">Pause</button>
                      <button style="display:none" style="display:none" id="video-pin-2"
                        class="video-tile-pin">Pin</button>
                    </div>
                    <div id="tile-3" class="video-tile">
                      <video id="video-3" class="video-tile-video"></video>
                      <div id="attendeeid-3" class="video-tile-attendeeid"></div>
                      <div id="nameplate-3" class="video-tile-nameplate"></div>
                      <div id="pause-state-3"></div>
                      <button style="display:none" id="video-pause-3" class="video-tile-pause">Pause</button>
                      <button style="display:none" style="display:none" id="video-pin-3"
                        class="video-tile-pin">Pin</button>
                    </div>
                    <div id="tile-4" class="video-tile">
                      <video id="video-4" class="video-tile-video"></video>
                      <div id="attendeeid-4" class="video-tile-attendeeid"></div>
                      <div id="nameplate-4" class="video-tile-nameplate"></div>
                      <div id="pause-state-4"></div>
                      <button style="display:none" id="video-pause-4" class="video-tile-pause">Pause</button>
                      <button style="display:none" style="display:none" id="video-pin-4"
                        class="video-tile-pin">Pin</button>
                    </div>
                    <div id="tile-5" class="video-tile">
                      <video id="video-5" class="video-tile-video"></video>
                      <div id="attendeeid-5" class="video-tile-attendeeid"></div>
                      <div id="nameplate-5" class="video-tile-nameplate"></div>
                      <div id="pause-state-5"></div>
                      <button style="display:none" id="video-pause-5" class="video-tile-pause">Pause</button>
                      <button style="display:none" style="display:none" id="video-pin-5"
                        class="video-tile-pin">Pin</button>
                    </div>
                    <div id="tile-6" class="video-tile">
                      <video id="video-6" class="video-tile-video"></video>
                      <div id="attendeeid-6" class="video-tile-attendeeid"></div>
                      <div id="nameplate-6" class="video-tile-nameplate"></div>
                      <div id="pause-state-6"></div>
                      <button style="display:none" id="video-pause-6" class="video-tile-pause">Pause</button>
                      <button style="display:none" style="display:none" id="video-pin-6"
                        class="video-tile-pin">Pin</button>
                    </div>
                    <div id="tile-7" class="video-tile">
                      <video id="video-7" class="video-tile-video"></video>
                      <div id="attendeeid-7" class="video-tile-attendeeid"></div>
                      <div id="nameplate-7" class="video-tile-nameplate"></div>
                      <div id="pause-state-7"></div>
                      <button style="display:none" id="video-pause-7" class="video-tile-pause">Pause</button>
                      <button style="display:none" style="display:none" id="video-pin-7"
                        class="video-tile-pin">Pin</button>
                    </div>
                    <div id="tile-8" class="video-tile">
                      <video id="video-8" class="video-tile-video"></video>
                      <div id="attendeeid-8" class="video-tile-attendeeid"></div>
                      <div id="nameplate-8" class="video-tile-nameplate"></div>
                      <div id="pause-state-8"></div>
                      <button style="display:none" id="video-pause-8" class="video-tile-pause">Pause</button>
                      <button style="display:none" style="display:none" id="video-pin-8"
                        class="video-tile-pin">Pin</button>
                    </div>
                    <div id="tile-9" class="video-tile">
                      <video id="video-9" class="video-tile-video"></video>
                      <div id="attendeeid-9" class="video-tile-attendeeid"></div>
                      <div id="nameplate-9" class="video-tile-nameplate"></div>
                      <div id="pause-state-9"></div>
                      <button style="display:none" id="video-pause-9" class="video-tile-pause">Pause</button>
                      <button style="display:none" style="display:none" id="video-pin-9"
                        class="video-tile-pin">Pin</button>
                    </div>
                    <div id="tile-10" class="video-tile">
                      <video id="video-10" class="video-tile-video"></video>
                      <div id="attendeeid-10" class="video-tile-attendeeid"></div>
                      <div id="nameplate-10" class="video-tile-nameplate"></div>
                      <div id="pause-state-10"></div>
                      <button style="display:none" id="video-pause-10" class="video-tile-pause">Pause</button>
                      <button style="display:none" style="display:none" id="video-pin-10"
                        class="video-tile-pin">Pin</button>
                    </div>
                    <div id="tile-11" class="video-tile">
                      <video id="video-11" class="video-tile-video"></video>
                      <div id="attendeeid-11" class="video-tile-attendeeid"></div>
                      <div id="nameplate-11" class="video-tile-nameplate"></div>
                      <div id="pause-state-11"></div>
                      <button style="display:none" id="video-pause-11" class="video-tile-pause">Pause</button>
                      <button style="display:none" style="display:none" id="video-pin-11"
                        class="video-tile-pin">Pin</button>
                    </div>
                    <div id="tile-12" class="video-tile">
                      <video id="video-12" class="video-tile-video"></video>
                      <div id="attendeeid-12" class="video-tile-attendeeid"></div>
                      <div id="nameplate-12" class="video-tile-nameplate"></div>
                      <div id="pause-state-12"></div>
                      <button style="display:none" id="video-pause-12" class="video-tile-pause">Pause</button>
                      <button style="display:none" style="display:none" id="video-pin-12"
                        class="video-tile-pin">Pin</button>
                    </div>
                    <div id="tile-13" class="video-tile">
                      <video id="video-13" class="video-tile-video"></video>
                      <div id="attendeeid-13" class="video-tile-attendeeid"></div>
                      <div id="nameplate-13" class="video-tile-nameplate"></div>
                      <div id="pause-state-13"></div>
                      <button style="display:none" id="video-pause-13" class="video-tile-pause">Pause</button>
                      <button style="display:none" style="display:none" id="video-pin-13"
                        class="video-tile-pin">Pin</button>
                    </div>
                    <div id="tile-14" class="video-tile">
                      <video id="video-14" class="video-tile-video"></video>
                      <div id="attendeeid-14" class="video-tile-attendeeid"></div>
                      <div id="nameplate-14" class="video-tile-nameplate"></div>
                      <div id="pause-state-14"></div>
                      <button style="display:none" id="video-pause-14" class="video-tile-pause">Pause</button>
                      <button style="display:none" style="display:none" id="video-pin-14"
                        class="video-tile-pin">Pin</button>
                    </div>
                    <div id="tile-15" class="video-tile">
                      <video id="video-15" class="video-tile-video"></video>
                      <div id="attendeeid-15" class="video-tile-attendeeid"></div>
                      <div id="nameplate-15" class="video-tile-nameplate"></div>
                      <div id="pause-state-15"></div>
                      <button style="display:none" id="video-pause-15" class="video-tile-pause">Pause</button>
                      <button style="display:none" style="display:none" id="video-pin-15"
                        class="video-tile-pin">Pin</button>
                    </div>
                    <div id="tile-16" class="video-tile">
                      <video id="video-16" class="video-tile-video"></video>
                      <div id="attendeeid-16" class="video-tile-attendeeid"></div>
                      <div id="nameplate-16" class="video-tile-nameplate"></div>
                      <div id="pause-state-16"></div>
                      <button style="display:none" id="video-pause-16" class="video-tile-pause"
                        class="btn">Pause</button>
                      <button style="display:none" style="display:none" id="video-pin-16" class="video-tile-pin"
                        class="btn">Pin</button>
                    </div>
                    <div id="tile-17" class="video-tile">
                      <video id="video-17" class="video-tile-video"></video>
                      <div id="attendeeid-17" class="video-tile-attendeeid"></div>
                      <div id="nameplate-17" class="video-tile-nameplate"></div>
                      <div id="pause-state-17"></div>
                      <button style="display:none" id="video-pause-17" class="video-tile-pause"
                        class="btn">Pause</button>
                      <button style="display:none" style="display:none" id="video-pin-17" class="video-tile-pin"
                        class="btn">Pin</button>
                    </div>
                    <div id="tile-18" class="video-tile">
                      <video id="video-18" class="video-tile-video"></video>
                      <div id="attendeeid-18" class="video-tile-attendeeid"></div>
                      <div id="nameplate-18" class="video-tile-nameplate"></div>
                      <div id="pause-state-18"></div>
                      <button style="display:none" id="video-pause-18" class="video-tile-pause"
                        class="btn">Pause</button>
                      <button style="display:none" style="display:none" id="video-pin-18" class="video-tile-pin"
                        class="btn">Pin</button>
                    </div>
                    <div id="tile-19" class="video-tile">
                      <video id="video-19" class="video-tile-video"></video>
                      <div id="attendeeid-19" class="video-tile-attendeeid"></div>
                      <div id="nameplate-19" class="video-tile-nameplate"></div>
                      <div id="pause-state-19"></div>
                      <button style="display:none" id="video-pause-19" class="video-tile-pause"
                        class="btn">Pause</button>
                      <button style="display:none" style="display:none" id="video-pin-19" class="video-tile-pin"
                        class="btn">Pin</button>
                    </div>
                    <div id="tile-20" class="video-tile">
                      <video id="video-20" class="video-tile-video"></video>
                      <div id="attendeeid-20" class="video-tile-attendeeid"></div>
                      <div id="nameplate-20" class="video-tile-nameplate"></div>
                      <div id="pause-state-20"></div>
                      <button style="display:none" id="video-pause-20" class="video-tile-pause"
                        class="btn">Pause</button>
                      <button style="display:none" style="display:none" id="video-pin-20" class="video-tile-pin"
                        class="btn">Pin</button>
                    </div>
                    <div id="tile-21" class="video-tile">
                      <video id="video-21" class="video-tile-video"></video>
                      <div id="attendeeid-21" class="video-tile-attendeeid"></div>
                      <div id="nameplate-21" class="video-tile-nameplate"></div>
                      <div id="pause-state-21"></div>
                      <button style="display:none" id="video-pause-21" class="video-tile-pause"
                        class="btn">Pause</button>
                      <button style="display:none" style="display:none" id="video-pin-21" class="video-tile-pin"
                        class="btn">Pin</button>
                    </div>
                    <div id="tile-22" class="video-tile">
                      <video id="video-22" class="video-tile-video"></video>
                      <div id="attendeeid-22" class="video-tile-attendeeid"></div>
                      <div id="nameplate-22" class="video-tile-nameplate"></div>
                      <div id="pause-state-22"></div>
                      <button style="display:none" id="video-pause-22" class="video-tile-pause"
                        class="btn">Pause</button>
                      <button style="display:none" style="display:none" id="video-pin-22" class="video-tile-pin"
                        class="btn">Pin</button>
                    </div>
                    <div id="tile-23" class="video-tile">
                      <video id="video-23" class="video-tile-video"></video>
                      <div id="attendeeid-23" class="video-tile-attendeeid"></div>
                      <div id="nameplate-23" class="video-tile-nameplate"></div>
                      <div id="pause-state-23"></div>
                      <button style="display:none" id="video-pause-23" class="video-tile-pause"
                        class="btn">Pause</button>
                      <button style="display:none" style="display:none" id="video-pin-23" class="video-tile-pin"
                        class="btn">Pin</button>
                    </div>
                    <div id="tile-24" class="video-tile">
                      <video id="video-24" class="video-tile-video"></video>
                      <div id="attendeeid-24" class="video-tile-attendeeid"></div>
                      <div id="nameplate-24" class="video-tile-nameplate"></div>
                      <div id="pause-state-24"></div>
                      <button style="display:none" id="video-pause-24" class="video-tile-pause"
                        class="btn">Pause</button>
                      <button style="display:none" style="display:none" id="video-pin-24" class="video-tile-pin"
                        class="btn">Pin</button>
                    </div>
                    <div id="tile-25" class="video-tile">
                      <div id="attendeeid-25" class="video-tile-attendeeid"></div>
                      <video id="video-25" class="video-tile-video"></video>
                      <div id="nameplate-25" class="video-tile-nameplate"></div>
                      <div id="pause-state-25"></div>
                      <button style="display:none" id="video-pause-25" class="video-tile-pause"
                        class="btn">Pause</button>
                      <button style="display:none" style="display:none" id="video-pin-25" class="video-tile-pin"
                        class="btn">Pin</button>
                    </div>
                    <div id="tile-26" class="video-tile">
                      <div id="attendeeid-26" class="video-tile-attendeeid"></div>
                      <video id="video-26" class="video-tile-video"></video>
                      <div id="nameplate-26" class="video-tile-nameplate"></div>
                      <div id="pause-state-26"></div>
                      <button style="display:none" id="video-pause-26" class="video-tile-pause"
                        class="btn">Pause</button>
                      <button style="display:none" style="display:none" id="video-pin-26" class="video-tile-pin"
                        class="btn">Pin</button>
                    </div>
                    <div id="tile-27" class="video-tile">
                      <div id="attendeeid-27" class="video-tile-attendeeid"></div>
                      <video id="video-27" class="video-tile-video"></video>
                      <div id="nameplate-27" class="video-tile-nameplate"></div>
                      <div id="pause-state-27"></div>
                      <button style="display:none" id="video-pause-27" class="video-tile-pause"
                        class="btn">Pause</button>
                      <button style="display:none" style="display:none" id="video-pin-27" class="video-tile-pin"
                        class="btn">Pin</button>
                    </div>
                  </div>
                </div>


              </div>
              <!-- CON-30 <div id="roster-message-container" class="hide-md-sm d-flex  flex-column col-12 col-sm-12 col-md-12 col-lg-3" style=" padding-bottom: 10px;"> -->
              <div id="roster-message-container" class="d-flex flex-column"
                style="min-width: 300px;flex-shrink: 0;padding: 10px;display: block;max-width: 300px;">

                <audio id="chatSound" style="display: none">
                  <source src="assets/etc/chatSound.mp3" type="audio/mpeg"></audio>
                <audio id="join-leave-sound" style="display: none">
                  <source src="assets/etc/join-leave-tone.mp3" type="audio/mpeg"></audio>
                <div class="bs-component" style="flex: 1 1 auto; overflow-y: auto;  ">
                  <ul id="roster" class="list-group"></ul>
                </div>
                <div class="message d-flex flex-column pt-3"
                  style="flex: 1 1 auto;overflow: hidden;min-height: 210px;max-height: 380px;">
                  <div class="list-group receive-message" id="receive-message" style="flex: 1 1 auto; overflow-y: auto;
                border: 1px solid rgba(0, 0, 0, 0.125); background-color: #fff"></div>
                  <div class="input-group send-message" style="display:flex;flex:0 0 auto;margin-top:0.2rem">
                    <textarea class="form-control shadow-none" id="send-message" rows="1"
                      placeholder="Type a message (markdown supported)" style="display:inline-block; width:100%;
                  resize:none; border-color: rgba(0, 0, 0, 0.125); outline: none; padding-left: 1.4rem"></textarea>
                  </div>
                </div>

                <div id="transcript-container" class="transcript-container"
                  style="display:none;min-height: 150px !important;margin-top: 5px;"></div>

              </div>
              <video id="content-share-video" playsinline crossOrigin="anonymous" style="display:none"></video>
            </div>
          </div>
        </div>

        <!-- Failure card if meeting cannot be joined -->

        <div id="flow-failed-join" class="flow">
          <div class="container">
            <form id="form-failed-join">
              <div class="card border-warning mb-3" style="max-width: 20rem;">
                <div id="failed-join" class="card-header"></div>
                <div class="card-body">
                  <h4 class="card-title">Unable to join meeting</h4>
                  <p class="card-text">There was an issue joining that meeting. Check your connectivity and try again.
                  </p>
                  <small id="failed-join-error" class="text-muted"></small>
                </div>
              </div>
              <button class="btn btn-lg btn-outline-warning btn-block" type="submit">OK</button>
            </form>
          </div>
        </div>


        <div id="transcript-container-duplicate" class="transcript-container-duplicate"
          style="display:none;min-height: 150px !important;margin-top: 5px;"></div>
      </div>
      
    </div> 
  </div> 
</div>

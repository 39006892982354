import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'; 
import { HttpClientModule } from '@angular/common/http';  
import { ChimeComponent } from '../../components/chime/chime.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,  
    ReactiveFormsModule,
    HttpClientModule  
  ],
  declarations: [ ChimeComponent],
  exports: [ChimeComponent],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA,
      NO_ERRORS_SCHEMA ]
})
export class SharedComponentsModule {
  static forRoot() {
    return {
        ngModule: SharedComponentsModule,
        providers: [],
    };
  }
 }

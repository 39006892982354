import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CcnChimeRoutingModule } from './ccn-chime-routing.module';
import { SharedComponentsModule } from 'src/app/shared/modules/shared-components/shared-components.module';
import { CcnChimeComponent } from './ccn-chime.component';
// import { ChimeComponent } from 'src/app/shared/components/chime/chime.component';


@NgModule({
  declarations: [CcnChimeComponent],
  imports: [
    CommonModule,
    CcnChimeRoutingModule,
    SharedComponentsModule
  ]
})
export class CcnChimeModule { }

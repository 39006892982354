import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CcnChimeComponent } from './ccn-chime.component';

const routes: Routes = [ 
  {
    path:'',
    component:CcnChimeComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CcnChimeRoutingModule { }

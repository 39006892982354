import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ccn-chime',
  templateUrl: './ccn-chime.component.html',
  styleUrls: ['./ccn-chime.component.scss']
})
export class CcnChimeComponent implements OnInit { 

  constructor(){ 
  }

  ngOnInit(): void {  
  }
}
